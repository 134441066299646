import React from 'react';
import './App.css';
import {logo} from "./assets/logo/logo";
import {Grid} from "@mui/material";
import Card from "./components/widgets/Card/Card";
import {Route, Routes} from "react-router-dom";
import {baseUrl} from "./shared/types/const";

function App() {
    const circles = <>
        <div className="circle circle1 color1"></div>
        <div className="circle circle2 color2"></div>
        <div className="circle circle3 color3"></div>
    </>

    const Header = () => (
        <Grid container direction="row" justifyContent="center" alignItems="center" className='header'>
            <span className='headerIcon'>
                {logo(32,24)}
            </span>
            <p className='headerTitle'>
                HELPSTER
            </p>
        </Grid>
    );



    return (
        <>
            <div className="wrapper">
                <div className="container font">
                    <main className='main'>
                        {circles}
                        <Header/>
                        <Routes>
                            <Route path={`/:id`} element={<Card/>} />
                        </Routes>
                    </main>
                </div>
            </div>
        </>

    );
}

export default App;
