import React from 'react';

const ShareIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.45256 20.655C2.37352 20.6554 2.29496 20.6428 2.22006 20.6175C2.06342 20.5667 1.92788 20.4657 1.83435 20.3301C1.74082 20.1946 1.69451 20.032 1.70256 19.8675C1.70256 19.755 2.47506 8.86502 13.3951 8.01002V4.09502C13.3949 3.94591 13.4392 3.80014 13.5224 3.67634C13.6055 3.55254 13.7236 3.45632 13.8616 3.39997C13.9997 3.34363 14.1514 3.32971 14.2974 3.36C14.4434 3.39028 14.5771 3.4634 14.6813 3.57002L22.0838 11.13C22.2212 11.2702 22.2982 11.4587 22.2982 11.655C22.2982 11.8513 22.2212 12.0398 22.0838 12.18L14.6813 19.74C14.5771 19.8466 14.4434 19.9198 14.2974 19.95C14.1514 19.9803 13.9997 19.9664 13.8616 19.9101C13.7236 19.8537 13.6055 19.7575 13.5224 19.6337C13.4392 19.5099 13.3949 19.3641 13.3951 19.215V15.375C6.10506 15.6525 3.11631 20.25 3.08631 20.3063C3.01864 20.4131 2.92506 20.5011 2.81426 20.5621C2.70345 20.6231 2.57903 20.655 2.45256 20.655ZM14.8951 5.93252V8.71127C14.8952 8.90571 14.8198 9.09261 14.6847 9.23253C14.5497 9.37245 14.3656 9.45446 14.1713 9.46127C7.10631 9.72002 4.57881 14.4263 3.67131 17.4525C5.54631 15.8325 8.88756 13.8525 14.1001 13.8525H14.1338C14.3327 13.8525 14.5235 13.9315 14.6641 14.0722C14.8048 14.2128 14.8838 14.4036 14.8838 14.6025V17.3813L20.5088 11.6588L14.8951 5.93252Z"
                fill="white"/>
        </svg>

    );
};

export default ShareIcon;