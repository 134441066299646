import React, {useEffect} from 'react';
import './VerticalLinearStepper.css'
import {Box, Step, StepIconProps, StepLabel, Stepper, styled} from "@mui/material";
import {Check} from "@mui/icons-material";
import dayjs from "dayjs";


const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({theme, ownerState}) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      //backgroundColor: 'transparent',
    }),
    '& .QontoStepIcon-uncompCheck': {
      color: '#ffffff',
    },
    '& .QontoStepIcon-compCheck': {
      color: '#070707',
    },
    '& .QontoStepIcon-circle': {
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: 'transparent',
      border: '1px solid white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: "center",
    },
    '& .QontoStepIcon-unCompCircle': {
      backgroundColor: 'white',
    },
  }),
);

function QontoStepIcon(props: StepIconProps) {
  const {active, completed, className} = props;

  return (
    <QontoStepIconRoot ownerState={{active}} className={className}>
      {completed ? (
        <div className="QontoStepIcon-circle QontoStepIcon-unCompCircle">
          <Check className="QontoStepIcon-compCheck"/>
        </div>
      ) : (
        <div className="QontoStepIcon-circle">
          <Check className="QontoStepIcon-uncompCheck"/>
        </div>
      )}
    </QontoStepIconRoot>
  );
}


interface Props {
  fundraising_date: Date | null
  treatment_date: Date | null
  Discharge_date: Date | null
  Payment_date: Date | null
}

export const VerticalLinearStepper: React.FC<Props> = ({
                                                         fundraising_date,
                                                         treatment_date,
                                                         Discharge_date,
                                                         Payment_date
                                                       }) =>
{
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<boolean[]>([false, false, false, false]);

  useEffect(() => {
    const dates = [fundraising_date, treatment_date, Discharge_date, Payment_date];
    const newCompleted = dates.map(date => !!date);
    const newActiveStep = newCompleted.lastIndexOf(false); // Index of the last incomplete step

    setActiveStep(newActiveStep);
    setCompleted(newCompleted);
  }, [fundraising_date, treatment_date, Discharge_date, Payment_date]);

  const steps = [
    {
      label: 'Date of admission',
      description: fundraising_date
    },
    {
      label: 'Fundraising completed',
      description: treatment_date
    },
    {
      label: 'Date of treatment',
      description: Discharge_date
    },
    {
      label: 'Invoice paid',
      description: Payment_date
    },
  ];


  return (
    <Box sx={{maxWidth: 400}}>
      <Stepper activeStep={activeStep}
               nonLinear
               orientation="vertical"
      >
        {steps.map((step, index) => (
          <Step key={step.label} completed={completed[index]}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              optional={step.description ? (
                <p className='stepperDesc'>{dayjs(step.description).format('MM.DD.YYYY')}</p>
              ) : (
                <p className='stepperDesc'>Awaiting</p>
              )}
            >
              <p className='descriptionTitle descSmall'>
                {step.label}
              </p>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}