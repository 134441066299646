import {Button, styled} from "@mui/material";

const ButtonShare = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 500,
    borderRadius: '88.52px',
    lineHeightStep: '22px',
    padding: '12px 48px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'transparent',
    borderColor: "white",
    fontFamily: 'Ubuntu',
    color: 'white',
    '&:hover': {
        background: 'rgba(255,255,255,0.19)',
        borderColor: "white",
        boxShadow: 'none',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

export default ButtonShare;