import React from 'react';

const ButtonIconCrown = () => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.73607 0.175841C8.6685 0.213392 8.56877 0.309616 8.51445 0.389654C8.46017 0.469692 7.63602 1.97171 6.68305 3.72747C5.49238 5.92116 4.93148 6.91429 4.88993 6.9023C4.85669 6.89262 3.93192 6.25434 2.8349 5.48382C0.86815 4.10244 0.837123 4.0829 0.611553 4.08221C0.366762 4.08142 0.233789 4.16437 0.0828449 4.41199C-0.0768031 4.67376 -0.0604433 4.79402 0.632023 8.44853L1.29116 11.9274H9H16.7088L17.368 8.44853C18.0604 4.79402 18.0768 4.67376 17.9172 4.41199C17.7655 4.16324 17.6335 4.08147 17.3836 4.08147H17.15L15.4068 5.50459C13.7289 6.87432 13.6609 6.92425 13.5939 6.83691C13.5556 6.78698 12.6054 5.29724 11.4824 3.52639C10.3593 1.75548 9.39473 0.269893 9.33884 0.225038C9.20321 0.116232 8.88947 0.0906214 8.73607 0.175841ZM1.62843 13.7161C1.82205 14.7667 1.95559 15.4292 1.98931 15.5063C2.01172 15.5576 2.09642 15.6615 2.17753 15.7373L2.32493 15.875H9.00399H15.683L15.7943 15.7732C16.0261 15.5613 16.0418 15.5054 16.3716 13.7161L16.4057 13.5311H9H1.59434L1.62843 13.7161Z"
                  fill="white"/>
        </svg>

    );
};
 export default ButtonIconCrown;