import {Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import './Card.css'
import {LinearProgressWithLabel} from "../../UI/LinearProgressWithLabel/LinearProgressWithLabel";
import ButtonHelp from "../../UI/Button/ButtonHelp";
import ButtonIconCrown from "../../../assets/logo/ButtonIconCrown";
import ButtonShare from "../../UI/Button/ButtonShare";
import ShareIcon from "../../../assets/logo/ShareIcon";
import {VerticalLinearStepper} from "../Stepper/VerticalLinearStepper";
import {ChildT} from "../../../shared/types/types";
import {baseUrl, fields} from "../../../shared/types/const";
import PrognosisIcon from "../../../assets/logo/PrognosisIcon";
import Qr from '../../../assets/img/Qr.png'
import AppStore from '../../../assets/img/AppStore.png'
import GooglePay from '../../../assets/img/GooglePlay.png'
import {useParams} from "react-router-dom";


const Card = () => {
  const {id} = useParams();
  const [child, setChild] = useState<ChildT>();
  console.log(child)

  const url = `${baseUrl}/items/patients/${id}?fields=${fields}`;

  const fetchData = async () => {
    try {
      const data = await fetch(`${url}`)
      const json = await data.json()

      return json.data
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
    }
  };

  useEffect(() => {
    fetchData().then((data) => {
      setChild(data);
    });
  }, []);

  const statusBtn = () => {
    return child?.status === 'Treatment'
      ? <p className='statusLabel' style={{background: '#FFCA00'}}>Treatment</p>
      : child?.status === 'Report'
        ? <p className='statusLabel' style={{background: '#6207EB'}}>Report</p>
        : child?.status === 'Fundraising'
          ? <p className='statusLabel' style={{background: '#13C294'}}>Fundraising</p>
          : <p className='statusLabel' style={{background: '#6207EB'}}>All</p>;
  }

  const shareOrDiscoverBtn = () => {
    var max = child?.amount as number;
    var value = child?.sum_fundraised as number;
    return Math.round(max - value) === 0 ?
      <a href='https://portal.helpstercharity.us' target='_blank' rel="noreferrer">
        <ButtonHelp>
          Discover other kids
        </ButtonHelp>
      </a>
      :
      <a href='https://helpstercharity.org/help' target='_blank' rel="noreferrer">
        <ButtonHelp startIcon={<ButtonIconCrown/>}>
          Donate
        </ButtonHelp>
      </a>
  }


  return (
    <div className='row card'>
      <Grid container maxWidth="md">
        <Grid container
            xs={12}
            sm={6}
            md={5}>
            <span className='photoContainer'>
              <img src={`${baseUrl}/assets/${child?.avatar}`} alt='' className='photo'/>
              {statusBtn()}
            </span>
        </Grid>
        <Grid container
            xs={12}
            sm={6}
            md={7}>
          <div className='cardText'>
            <p className='cardTitle'>{child?.first_name} {child?.last_name}, {child?.age}</p>
            <p className='cardDescription'>
              {child?.history}
            </p>
            <div className='linearProgress'>
              <LinearProgressWithLabel value={child?.sum_fundraised as number} maxValue={child?.amount as number}/>
            </div>
            <div className='childInfo'>
              <Grid container direction="row" className='childInfo__row'>
                <p className='childInfo__title'>Health Problem:</p>
                <p className='childInfo__desc'>{child?.problem.name}</p>
              </Grid>
              <Grid container direction="row" className='childInfo__row'>
                <p className='childInfo__title'>Hospital:</p>
                <p className='childInfo__desc'>{child?.hospital.title}</p>
              </Grid>
              <Grid container direction="row" className='childInfo__row'>
                <p className='childInfo__title'>Child's location:</p>
                <p className='childInfo__desc'>{child?.country}, {child?.city}</p>
              </Grid>
            </div>

            <div>
              {shareOrDiscoverBtn()}
              <ButtonShare className="share" startIcon={<ShareIcon/>}><span className="share-view">Share</span></ButtonShare>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid container
              xs={12}
              sm={6}
              md={4}>
            <div className='issueCard'>
              <p className='issueTitle'>
                Сhronology
              </p>
              <div className='miniCard'>
                <VerticalLinearStepper fundraising_date={child?.fundraising_date ?? null}
                                      treatment_date={child?.treatment_date ?? null}
                                      Discharge_date={child?.Discharge_date ?? null}
                                      Payment_date={child?.Payment_date ?? null}
                />
              </div>
            </div>
        </Grid>
        <Grid container
              xs={12}
              sm={6}
              md={8}>
          <Grid container direction="row" className='issuesBlock'>
            <Grid container
                  xs={12}
                  sm={12}
                  md={6}>
              <div className='issueCard'>
                <p className='issueTitle'>
                  Health issue
                </p>
                <Grid container
                      flexDirection='column'
                      justifyContent='center'
                      alignItems="center"
                      className='miniCard'
                >
                  <img src={`${baseUrl}/assets/${child?.problem.icon}`} alt='' className='issueIcon'/>
                  <p className='descSmall prognosisTitle textAlign'>{child?.problem.name}</p>
                  <p className='textAlign'>{child?.problem_description}</p>
                </Grid>
              </div>
            </Grid>
            <Grid container
                  xs={12}
                  sm={12}
                  md={6}>
              <div>
                <p className='issueTitle'>
                  Prognosis
                </p>
                <Grid container
                      flexDirection='column'
                      justifyContent='center'
                      alignItems="center"
                      className='miniCard'
                >
                  <div className='issueIcon'>
                    <PrognosisIcon/>
                  </div>
                  <p className='textAlign'>{child?.doctor_opinion}</p>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <div className='downloadTitle'>
            <p>Download Helpster App for more information</p>
          </div>
          <Grid container
                flexDirection='row'
                justifyContent='center'
                alignItems="center"
                className='miniCard mediumCard'
          >
            <span className='qrImg'>
              <img src={Qr} alt=''/>
            </span>
            <div>
              <Grid container
                    flexDirection='column'
                    alignItems="center"
              >
                <a className='googleImg' href="https://helpstercharity.app.link/nFir7kzZwCb" target='_blank' rel="noreferrer">
                  <img src={AppStore} alt='AppStore'/>
                </a>
                <a href="https://helpstercharity.app.link/nFir7kzZwCb" target='_blank' rel="noreferrer">
                  <img src={GooglePay} alt='GooglePay'/>
                </a>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Card;