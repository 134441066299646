import {Button, styled} from "@mui/material";

const ButtonHelp = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 500,
    borderRadius: '88.52px',
    lineHeightStep: '22px',
    padding: '12px 48px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#CC01D0',
    borderColor: "white",
    fontFamily: 'Ubuntu',
    margin: '0 20px 0 0',
    color: 'white',
    '&:hover': {
        background: '#d846db',
        borderColor: "white",
        boxShadow: 'none',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

export default ButtonHelp;