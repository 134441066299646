import React from 'react';

const PrognosisIcon = () => {
    return (
        <svg width="86" height="48" viewBox="0 0 86 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24.625" cy="24" r="22.5" fill="#E6D7FF" stroke="#8737FF" strokeWidth="3"/>
            <circle cx="18.625" cy="19.5" r="3" fill="#8737FF"/>
            <circle cx="30.625" cy="19.5" r="3" fill="#8737FF"/>
            <path d="M31.9375 31H17.5" stroke="#8737FF" strokeWidth="3" strokeLinecap="round"/>
            <circle cx="61.375" cy="24" r="22.5" fill="#E6D7FF" stroke="#8737FF" strokeWidth="3"/>
            <path d="M67.3197 28.5262C68.0702 31.3269 66.4081 34.2056 63.6074 34.9561C60.8067 35.7065 58.1531 34.0397 57.1775 31.2438" stroke="#8737FF" stroke-width="3" stroke-linecap="round"/>
            <path d="M51.7561 22.6025C51.4345 21.4022 52.1468 20.1684 53.3471 19.8468C54.5474 19.5252 55.6847 20.2396 56.1028 21.4378" stroke="#8737FF" stroke-width="3" stroke-linecap="round"/>
            <path d="M63.3473 19.4967C63.0257 18.2964 63.738 17.0626 64.9383 16.741C66.1386 16.4194 67.2758 17.1337 67.694 18.332" stroke="#8737FF" stroke-width="3" stroke-linecap="round"/>
        </svg>

    );
};

export default PrognosisIcon;