import React from 'react';
import {Box, Grid, LinearProgress, linearProgressClasses, LinearProgressProps, styled} from "@mui/material";
import './LinearProgressWithLabel.css'


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 100,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        background: '#FFFFFF12 7%',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 100,
        backgroundColor: '#8A3DFF',
    },
}));

export function LinearProgressWithLabel(props: LinearProgressProps & { value: number } & { maxValue: number }) {
    const progressPercentage = (props.value / props.maxValue) * 100;
    return (
        <Box>
            <Grid container
                  direction="row"
                  justifyContent='space-between'
                  alignItems="center"
                  className='linearProgressDescription linearProgressDescription__num'
            >
                <p>
                    {Math.round(props.maxValue - props.value) === 0 ? '100% funded' : `Amount Left: $${Math.round(props.maxValue - props.value)}`}
                </p>
                <span>
                    <Grid container direction="row">
                    <p>
                        {`$${Math.round(props.value)}${Math.round(props.maxValue - props.value) === 0 ? "" : "/"}`}
                    </p>
                    <p className='linearProgressDescription__num__all'>
                        {`${Math.round(props.maxValue - props.value) === 0 ? "" : Math.round(props.maxValue)}`}</p>
                </Grid>
                </span>
            </Grid>
            <Box sx={{width: '100%', mr: 1}}>
                <BorderLinearProgress variant="determinate" value={progressPercentage} />
            </Box>
        </Box>
    );
}